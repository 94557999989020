import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchNoticeDetails } from '../../services/publicTendersApi';
import pdfIcon from '../../assets/icons/pdf-icon.png';
import docIcon from '../../assets/icons/doc-icon.png';
import xlsIcon from '../../assets/icons/xls-icon.png';
import zipIcon from '../../assets/icons/zip-icon.png';
import defaultIcon from '../../assets/icons/file-icon.png';
import './AuctionDetails.css';
import Utility from './../../utils/Utility';
import SeoPageComponent from '../../components/SeoPageComponent/SeoPageComponent';
import Breadcrumbs from '../../components/Breadcrumbs';
import RestrictedAccess from '../../components/RestrictedAccess/RestrictedAccess';
import ItemLoadingHolder from '../../components/LoadingHolders/ItemLoadingHolder';

const AuctionDetails = () => {
  const { noticeNo } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDetails = async () => {
      try {
        setLoading(true);
        const data = await fetchNoticeDetails(noticeNo);
        setDetails(data);
      } catch (err) {
        setError(err.message || 'Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    getDetails();
  }, [noticeNo]);

  const getFileIcon = (extension) => {
    switch (extension) {
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return docIcon;
      case 'xls':
      case 'xlsx':
        return xlsIcon;
      case 'zip':
      case 'rar':
      case '7z':
      case 'tar':
        return zipIcon;
      default:
        return defaultIcon;
    }
  };

  const handleFileDownload = async (doc) => {
    const { noticeDocumentUrl, noticeDocumentName } = doc;
    const cNoticeId = details.specificView?.cNoticeId;
    const noticeNo = details.specificView?.noticeNo;

    if (!noticeDocumentUrl || !noticeDocumentName || !cNoticeId || !noticeNo) {
      console.error('Missing data for file download.');
      alert('Some required information is missing. Please try again.');
      return;
    }

    try {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/api/SU_ContractList/download/?noticeDocumentUrl=${encodeURIComponent(
        noticeDocumentUrl,
      )}&noticeDocumentName=${encodeURIComponent(
        noticeDocumentName,
      )}&cNoticeId=${cNoticeId}&noticeNo=${encodeURIComponent(noticeNo)}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/octet-stream',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download the file. Please try again.');
      }

      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');

      let fileName = noticeDocumentName.replace(/\.p7m|\.p7s$/, '');
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="(.+)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error during file download:', error);
      alert('An error occurred while attempting to download the file.');
    }
  };

  const handleClarificationDownload = async (doc, cNoticeId, noticeNo) => {
    const { noticeDocumentUrl, documentName } = doc;
    const fileName = documentName;

    const endpoint =
      `${process.env.REACT_APP_BASE_URL}/api/ContractListWeb/DownloadClarification` +
      `?noticeDocumentUrl=${encodeURIComponent(noticeDocumentUrl)}` +
      `&fileName=${encodeURIComponent(fileName)}` +
      `&cNoticeId=${encodeURIComponent(cNoticeId)}` +
      `&noticeNo=${encodeURIComponent(noticeNo)}`;

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: { Accept: 'application/octet-stream' },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend error:', errorText);
        throw new Error('Failed to download the file.');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = downloadUrl;
      link.download = fileName.replace(/\.p7m|\.p7s$/, '');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading clarification:', error);
      alert('A apărut o eroare la descărcarea clarificării.');
    }
  };

  if (loading) {
    return <ItemLoadingHolder />;
  }

  if (error) {
    return <p className="error-text">Eroare: {error}</p>;
  }

  if (!details) {
    return <p>Nu există date disponibile.</p>;
  }

  const breadcrumbLinks = [
    { href: '/', label: 'Home' },
    { href: '/licitatii', label: 'Licitatii' },
    { label: details.stickyData?.contractTitle || 'N/A' },
  ];

  const pageTitle = `${
    details.stickyData?.contractTitle || 'Detalii Licitație'
  } - ${process.env.REACT_APP_NAME}`;
  const pageDescription = `Informații despre licitația ${
    details.stickyData?.contractTitle || 'N/A'
  }, publicată de ${details.stickyData?.caName || 'N/A'}.`;
  const pageUrl = `${process.env.REACT_APP_DOMAIN}/licitatii/${noticeNo}`;
  const logoUrl = `${process.env.REACT_APP_BASE_URL}/assets/logo.png`;

  return (
    <>
      <SeoPageComponent
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageUrl={pageUrl}
        logoUrl={logoUrl}
        keywords={'achiziții publice, licitații, construcții, Go4Projects'}
      />
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />

      <section className="py-20 bg-slate-100">
        <div className="container">
          {/* section title */}
          <div className="max-w-3xl mx-auto">
            <h1 className="text-center capitalize text-3xl md:text-5xl font-bold text-gray-700 mb-4 w-fit mx-auto relative after:absolute after:w-4/5 after:-bottom-4 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-b-4 after:border-indigo-500 after:border-double">
              Detalii Licitație
            </h1>
          </div>

          <div className="mt-16 row">
            <div className="col-2-3 bg-white shadow-xl rounded p-5 group">
              <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
                Informații Cheie
              </h2>

              <div className="space-y-3">
                <div>
                  <h4 className="text-gray-800">
                    <strong>Titlu Contract:</strong>
                  </h4>
                  <p className="text-gray-700">
                    {details.stickyData?.contractTitle || 'N/A'}
                  </p>
                </div>

                <div>
                  <h4 className="text-gray-800 mb-1">
                    <strong>Număr Licitație:</strong>
                  </h4>

                  <RestrictedAccess
                    compact={true}
                    message="Numar licitatie disponibil doar pentru utilizatorii autentificati"
                  >
                    <p> {details.stickyData?.noticeNumber || 'N/A'}</p>
                  </RestrictedAccess>
                </div>

                <div>
                  <h4 className="text-gray-800 mb-1">
                    <strong>Autoritate Contractantă:</strong>
                  </h4>

                  <RestrictedAccess
                    compact={true}
                    message="Informatie despre autoritate contractanta disponibila doar pentru utilizatorii autentificati"
                  >
                    <p>
                      {details.stickyData?.caName || 'N/A'}
                      <br />{' '}
                      {details.section1View.noticeEntityAddress.Address ||
                        'N/A' +
                          ' ' +
                          details.Section1View.NoticeEntityAddress.PostalCode ||
                        'N/A'}
                    </p>
                  </RestrictedAccess>
                </div>

                <p>
                  <strong className="text-gray-800">Tip Procedura:</strong>{' '}
                  {details.stickyData.sysProcedureType || 'N/A'}
                </p>
              </div>
            </div>

            <div className="col-1-3">
              <div className="bg-white shadow-xl rounded p-5 group">
                <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
                  Termene
                </h2>

                <div className="space-y-3">
                  <div>
                    <h4 className="text-gray-800 mb-1">
                      <strong>Termen valabilitate ofertă:</strong>
                    </h4>

                    <RestrictedAccess
                      compact={true}
                      message="Data limita de depunere disponibila doar pentru utilizatorii autentificati"
                    >
                      <p>
                        {noticeNo.startsWith('CN')
                          ? details.section4View?.tenderAvailabilityDeadline
                          : Utility.FormatDateLight(
                              details.section4View?.tenderAvailabilityDeadline,
                            ) || 'N/A'}
                      </p>
                    </RestrictedAccess>
                  </div>

                  <p>
                    <strong className="text-gray-800">Dată Publicare:</strong>{' '}
                    {Utility.FormatDate(details.stickyData?.publicationDate) ||
                      'N/A'}
                  </p>

                  <p>
                    <strong className="text-gray-800">Termen Limita:</strong>{' '}
                    {details.section4View?.tenderReceiptDeadline ||
                      details.section4View?.tenderOpeningDate ||
                      'N/A'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-1-3">
              <div className="bg-white shadow-xl rounded p-5 group">
                <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
                  Valori Financiare
                </h2>

                <div className="space-y-3">
                  <div className="flex items-center gap-1">
                    <strong className="text-gray-800">Valoare Estimată:</strong>

                    <p className="text-gray-600">
                      {Utility.ConvertEstimatedValue(
                        details.section21View.estimatedValue,
                      )}{' '}
                      {details.section21View.currencyCode}
                    </p>
                  </div>

                  <p>
                    <strong className="text-gray-800">
                      Garanție Participare:
                    </strong>{' '}
                    {details.section6View?.depositsAndWarranties || 'N/A'}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-1-3">
              <div className="bg-white shadow-xl rounded p-5 group">
                <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
                  Coduri CPV
                </h2>
                <strong className="text-gray-800">Cod CPV:</strong>{' '}
                <p className="text-gray-600 inline">
                  {details.section21View?.mainCpvCode || 'N/A'}
                </p>
              </div>
            </div>

            <div className="col-1-3">
              <div className="bg-white shadow-xl rounded p-5 group">
                <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
                  Fișiere Disponibile
                </h2>

                <RestrictedAccess message="Sectiunea fisiere disponibila doar pentru utilizatorii autentificati">
                  <div className="section-content">
                    {details.dfNoticeSectionFiles?.dfNoticeDocs?.length > 0 ? (
                      details.dfNoticeSectionFiles.dfNoticeDocs.map(
                        (doc, index) => {
                          const fileName =
                            doc.noticeDocumentName.replace(
                              /\.p7m|\.p7s$/,
                              '',
                            ) || 'Document';
                          const fileExtension = fileName
                            .split('.')
                            .pop()
                            .toLowerCase();

                          return (
                            <div key={index} className="file-item">
                              <img
                                src={getFileIcon(fileExtension)}
                                alt={`${fileExtension} icon`}
                                className="file-icon"
                              />
                              <button
                                onClick={() => handleFileDownload(doc)}
                                className="download-link"
                              >
                                {fileName}
                              </button>
                            </div>
                          );
                        },
                      )
                    ) : (
                      <p>Nu există fișiere disponibile.</p>
                    )}
                  </div>
                </RestrictedAccess>
              </div>
            </div>
          </div>

          <div className="bg-white shadow-xl rounded p-5 group mt-5">
            <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
              Situație economică și financiară
            </h2>

            <p className="text-gray-600">
              {details.section3View?.depositsAndWarranties || 'N/A'}
            </p>
          </div>

          <div className="bg-white shadow-xl rounded p-5 group mt-5">
            <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
              {' '}
              Capacitate tehnică și profesională
            </h2>

            <p className="text-gray-600">
              <p>{details.section3View?.tpCriteriaQAStandardMin || 'N/A'}</p>
            </p>
          </div>
        </div>
      </section>
      <section className="py-20 bg-slate-100">
        <div className="container">
          <div className="bg-white shadow-xl rounded p-5 group mt-5">
            <h2 className="border-b border-dashed group-hover:border-indigo-500 text-3xl text-indigo-500 font-semibold pb-3 mb-4 duration-300">
              Clarificări
            </h2>

            <RestrictedAccess message="Sectiunea clarificari disponibila doar pentru utilizatorii autentificati">
              <div className="section-content">
                {details.clarifications?.items?.length > 0 ? (
                  details.clarifications.items.map((doc, index) => {
                    const fileName =
                      doc.documentName.replace(/\.p7m|\.p7s$/, '') ||
                      'Document';
                    const fileExtension = fileName
                      .split('.')
                      .pop()
                      .toLowerCase();
                    return (
                      <div key={index} className="file-item">
                        <img
                          src={getFileIcon(fileExtension)}
                          alt={`${fileExtension} icon`}
                          className="file-icon"
                        />
                        <button
                          onClick={() =>
                            handleClarificationDownload(
                              doc,
                              details.specificView?.cNoticeId,
                              details.specificView?.noticeNo,
                            )
                          }
                          className="download-link"
                        >
                          {fileName}
                        </button>
                        <div className="text-gray-500 text-sm">
                          {doc.noticeDocumentDescription}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>Nu există clarificări disponibile.</p>
                )}
              </div>
            </RestrictedAccess>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuctionDetails;
